const heights = {
    xs: "h-1",
    sm: "h-2",
    md: "h-4",
    lg: "h-6",
    xl: "h-8",
}

const colors = {
    blue: "bg-blue-600",
    green: "bg-green-600",
    red: "bg-red-600",
    yellow: "bg-yellow-500",
    orange: "bg-orange-400",
}

type height = keyof typeof heights

export default function ProgressBar({progress, indeterminate, height = "md", color = 'blue', max = 100}: {
    progress: number,
    indeterminate?: boolean,
    height?: height,
    color?: keyof typeof colors,
    max?: number,
}) {
    const heightClass = heights[height]
    const scaledProgress = progress / max * 100
    return (
        <div className={`w-full bg-gray-200 rounded-full ${heightClass} overflow-hidden`}>
            <div
                className={`${colors[color]} ${heightClass} rounded-full ${scaledProgress < 100 && indeterminate ? 'progress left-right' : ''}`}
                style={{width: !indeterminate ? `${scaledProgress}%` : '100%'}}
            ></div>
        </div>
    )
}
