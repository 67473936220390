import React, { useState, useEffect, FC, createElement, Fragment } from "react";

/** React component that renders its children client-side only / after first mount */
export default function ClientRender({ children }: { children: React.ReactNode }) {
    const [isMounted, setIsMounted] = React.useState(false);

    React.useEffect(() => {
        setIsMounted(true);
    }, []);

    return isMounted ? children : null;
}
