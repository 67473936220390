import axios from "axios";
import {initProgressListener} from "@/utils/progressListener";
import ProgressSmoother, {ProgressSmootherConfig} from "@/utils/ProgressSmoother";
import * as Sentry from "@sentry/react";

type RemoteUploadResult = {
    fileName: string,
    fileSize: number,
    id: string,
}

const progressConfig: ProgressSmootherConfig = {
    maxValue: 1,
    averageTimeBetweenValues: 600,
    averageValueIncreaseDelta: 0.05,
    delayUntilFirstValue: 10000,
    firstValue: 0.1,
}

export default async function uploadRemoteVideo(
    url: string,
    onProgress: (progress: number) => void,
): Promise<RemoteUploadResult> {
    return new Promise(async (resolve, reject) => {
        const onComplete = (result: RemoteUploadResult) => resolve(result);
        const onError = (error: Error) => reject(error);
        const progressSmoother = new ProgressSmoother(progressConfig);
        const uploadRoute = route('upload.url');

        try {
            const res = await axios.post(uploadRoute, { url });
            const {uploadId} = res.data;
            await initProgressListener(
                uploadId,
                onProgress,
                res => onComplete({...res, id: uploadId}),
                onError,
                progressSmoother,
                500
            );
        } catch (e: any) {
            if (e.response) {
                const data = e.response.data;
                const msg = data.message ? data.message : JSON.stringify(data);
                onError(new Error(msg));
            } else {
                onError(e);
            }
            if((e.message || '').includes('Unable to download video')) {
                Sentry.captureException(e);
            }
        }
    });
}
