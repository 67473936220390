import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head, Link, router, useForm} from "@inertiajs/react";
import PrimaryButton from "@/Components/PrimaryButton";
import Layout from "@/Pages/Api/ApiPageLayout";
import ApiOverview from "@/Pages/Api/ApiOverview";
import Checkbox from "@/Components/Checkbox";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import React, {FormEventHandler} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import InputLabel from "@/Components/InputLabel";
import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import {Transition} from "@headlessui/react";



export default function ({  }) {
    const {data, setData, post, errors, processing, recentlySuccessful} = useForm({
        name: '',
        expires_at: '',
    });

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        post(route('api-keys.store'));
    };

    return (
        <>
            <Head title="Subtitle Extraction API">
                <meta name="description" content="Create an API key to access the Subtitle Extraction API."/>
            </Head>
            <Layout>
                <div className="flex justify-between items-center mx-6 mt-8">
                    <h2 className="text-xl ">Create API Key</h2>
                </div>
                <div className="mt-6 mx-6  bg-white border border-gray-200 rounded-lg shadow-sm">
                    <form onSubmit={submit} className="p-10 space-y-6 max-w-[40rem]">
                        <div>
                            <InputLabel htmlFor="name" value="Name"/>

                            <TextInput
                                id="name"
                                className="mt-1 block w-full"
                                value={data.name}
                                onChange={(e) => setData('name', e.target.value)}
                                required
                                isFocused
                                autoComplete="name"
                            />

                            <InputError className="mt-2" message={errors.name}/>
                        </div>

                        <div>
                            <InputLabel htmlFor="expires_at" value="Expires At"/>

                            <TextInput
                                id="expires_at"
                                type="date"
                                className="mt-1 block w-full"
                                value={data.expires_at}
                                onChange={(e) => setData('expires_at', e.target.value)}
                            />

                            <InputError className="mt-2" message={errors.expires_at}/>
                        </div>


                        <div className="flex items-center gap-4">
                            <PrimaryButton disabled={processing}>Submit</PrimaryButton>
                        </div>
                    </form>
                </div>

            </Layout>
        </>
    )
}
