export default function () {
    const currentYear = new Date().getFullYear();
    return (
        <div className="mx-auto container w-full p-4 py-6 lg:py-8">
            <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8"/>
            <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center ">© {currentYear} <a
                        href={route('home')} className="hover:underline">Subtitle Extractor</a>. All Rights Reserved.
                </span>
                <div className="flex mt-4 sm:justify-center sm:mt-0">


                    <a href="/blog" className="text-gray-500 hover:text-gray-900 ">
                        Blog
                    </a>
                    <a href="/contact" className="text-gray-500 hover:text-gray-900 ms-5">
                        Contact
                    </a>

                    <a href={route('faqs')} className="text-gray-500 hover:text-gray-900 ms-5">
                        FAQ
                    </a>

                    <a href={route('privacy')} className="text-gray-500 hover:text-gray-900 ms-5">
                        Privacy
                    </a>
                </div>
            </div>

        </div>
    )
}
