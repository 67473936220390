import iframeScript from "./iframeScript?raw";

const GOOGLE_TRANSLATE_URL = 'https://translate.google.com/translate_a/element.js?cb=';

type TranslationResult = {
    status: 'success' | 'error';
    error?: string;
    content: string[];
}

export function translate(texts: string[], targetLanguage: string, debug: boolean = false): Promise<string[]> {
    return new Promise((resolve, reject) => {
        let result: string[] = [];
        const iframe = createIframeWithHTML(`
            <html>
                <head>
                <title>Subtitles</title>
                    <script src="https://code.jquery.com/jquery-3.7.1.min.js" integrity="sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo=" crossorigin="anonymous"></script>
                    <script>
                        ${iframeScript.replace(/{targetLanguage}/g, targetLanguage)}
                    </script>
                    <script type="text/javascript" src="${GOOGLE_TRANSLATE_URL}on_loaded"></script>
                </head>
                <body>
                    <div id="google_element"></div>
                    <div id="content">
                        ${texts.map((text) => `<p>${text}</p>`).join('')}
                    </div>

                </body>
            </html>
        `, debug);

        const onComplete = (event: MessageEvent) => {
            const res = event.data as TranslationResult;
            iframe.remove();
            if(res.status === 'success') {
                resolve(res.content);
            } else {
                reject(new Error(res.error));
            }
        }

        window.addEventListener('message', onComplete, {once: true});
        return result;
    });
}

function createIframeWithHTML(htmlString: string, visible: boolean = false): HTMLIFrameElement {
    const iframe = document.createElement('iframe');
    iframe.className = `fixed top-0 left-0 w-full h-full ${visible ? '' : 'invisible'}`;
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentDocument || iframe.contentWindow!.document;
    iframeDoc.open();
    iframeDoc.write(htmlString);
    iframeDoc.close();
    return iframe;
}
