
import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head, Link, usePage} from "@inertiajs/react";
import PrimaryButton from "@/Components/PrimaryButton";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";


export type ApiLayoutProps = {
    children: React.ReactNode
}
export default function ({ children }: ApiLayoutProps) {
    const { auth: { user } } = usePage<PageProps>().props

    return (
        <>
            <Head title="Subtitle Extraction API">
                <meta name="description" content="Extract subtitles from videos using a simple API."/>
            </Head>
            <PageLayout>
                {
                    (authenticated) => (
                        <div className="container mx-auto md:flex">
                            <aside id="sidebar"
                                   className="w-full md:w-1/5 px-4 md:px-0 md:ml-6 block md:flex flex-col pt-10 border-r md:pr-5">
                                <ul className="py-1 list-unstyled fw-normal small">
                                    <li>
                                        <Link
                                            href={route('api-info')}
                                            className={`flex items-center mt-2   p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('api-info') ? (user ? 'bg-white' : 'bg-gray-100') : ''} `}
                                        >
                                            <span className="ml-3">API Overview</span>
                                        </Link>
                                    </li>
                                    {
                                        authenticated && (
                                            <li>
                                                <Link
                                                    href={route('api-keys.index')}
                                                    className={`flex items-center mt-2   p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('api-keys.*') ? 'bg-white' : ''} `}
                                                >
                                                    <span className="ml-3">API Keys</span>
                                                </Link>
                                            </li>
                                        )
                                    }
                                    <li>
                                        <a href={route('api-docs')}
                                           target={"_blank"}
                                           className="flex items-center  mt-2 p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group">
                                            <span className="ml-3 mr-1 ">Documentation </span><FontAwesomeIcon
                                            icon={faUpRightFromSquare}
                                            className="w-3 h-3"
                                        />
                                        </a>
                                    </li>
                                </ul>
                            </aside>
                            <div className="min-h-[70vh] w-full md:w-4/5  md:flex-col ">
                                {children}
                            </div>
                        </div>
                    )

                }
            </PageLayout>
        </>
    )
}



