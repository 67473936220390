import Modal from "@/Components/Modal";
import {useDropzone} from 'react-dropzone';
import Select from "react-select";
import {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {bytesToSize, formatMinutes, replaceFileExtension} from "@/utils/textFormatting";
import uploadFile from "@/services/localFileUploader"
import {v4 as uuidv4} from "uuid";
import ProgressBar from "@/Components/ProgressBar";
import {faEye, faLink, faTriangleExclamation, faVolumeHigh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ExtractorProps, JobDetails} from "@/types";
import Spinner from "@/Components/Spinner";
import {getVideoDuration} from "@/services/extractionJobManager";
import Popover from "@/Components/Popover";
import RemoteUploadModal from "@/Pages/Dashboard/Partials/RemoteUploadModal";
import {ExtractionMethod} from "@/types/enums";
import TrashIcon from "@/Components/Icons/TrashIcon";
import AdvancedSettingsModal, {
    AdvancedSettings,
    DEFAULT_SUBTITLE_POSITION
} from "@/Pages/Dashboard/Partials/AdvancedSettingsModal";
import CloseIcon from "@/Components/Icons/CloseIcon";
import audioExtractor from "@/services/video/AudioExtractor";
import PurchaseModal from "@/Pages/Dashboard/Partials/PurchaseModal";
import {isDesktop} from "@/utils/deviceUtils";
import UploadItem from "@/Pages/Dashboard/Upload/UploadItem";
import axios from "axios";
import FFmpeg from "@/services/video/FFmpeg";
import {isAudioFile} from "@/utils/fileUtils";
import useExtractionLanguages from "@/hooks/useExtractionLanguages";
import InsufficientCreditsException from "@/exceptions/InsufficientCreditsException";

const DEFAULT_LANGUAGE = 'Auto-Detect'
const LANGUAGE_KEY = 'language'
const METHOD_KEY = 'method'
const ETA_UPDATE_INTERVAL = 1000
const AUDIO_ETA_THRESHOLD = 270

export type UploadModalProps = {
    credits: number,
    onCreditRefresh: () => Promise<number>,
    show: boolean,
    onClose: () => void,
    submit: (uploads: JobDetails[]) => Promise<Record<string, Error>>,
    extractorParams: ExtractorProps,
    initialFiles?: File[],
    initialUpload?: RemoteUpload | null,
    extractAudioClientSide: boolean,
    multiple: boolean,
}

interface Upload {
    id: string,
    fileName: string,
    fileSize: number,
    progress: number,
    isAudioOnly?: boolean,
    options: Record<string, any>,
    error?: string,
    abortController?: AbortController,
}

export interface LocalUpload extends Upload {
    file: File,
    abortController: AbortController,
    uploadDuration?: number
    originalFile?: File
    step?: 'uploading' | 'extracting',
}

export interface RemoteUpload extends Upload {
    url: string
}

type UploadEta = {
    etaSeconds: number,
    lastUpdate: number
}

function updateUserSetting(key: string, value: any) {
    const updateRoute = route('setting.updateItem')
    return axios.put(updateRoute, {key, value})
}

export default function ({
    show,
    onClose,
    extractorParams,
    submit,
    initialFiles,
    multiple,
    credits: initialCredits,
    onCreditRefresh,
    initialUpload,
    extractAudioClientSide
    }: UploadModalProps)
{
    const [credits, setCredits] = useState(initialCredits)
    const triggerFileDrop = useRef<Function>()
    const audioExtractionEnabled = useRef(extractAudioClientSide && isDesktop())
    const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
    const [uploads, setUploads] = useState<(LocalUpload|RemoteUpload)[]>([])
    const [method, setMethod] = useState<ExtractionMethod>(ExtractionMethod.OCR)
    const [videoDurations, setVideoDurations] = useState<Record<string, number>>({})
    const totalDurationSeconds = uploads.reduce((acc, upload) => acc + videoDurations[upload.id], 0)
    const [submitting, setSubmitting] = useState(false)
    const [showRemoteUploadModal, setShowRemoteUploadModal] = useState(false)
    const [showCreditPurchaseModal, setShowCreditPurchaseModal] = useState(false)
    const [advancedSettingsId, setAdvancedSettingsId] = useState<string | null>(null)
    const [submissionError, setSubmissionError] = useState<string>('')
    const [uploadEta, setUploadEta] = useState<Record<string, UploadEta>>({})
    const [disableAudioExtractions, setDisableAudioExtractions] = useState<Set<string>>(new Set())
    const [languageOptions, isLoadingLang] = useExtractionLanguages();
    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        multiple: true,
        accept: {
            'video/*': [],
            'audio/*': []
        },
        maxSize: extractorParams.maxFileSize,
        maxFiles: extractorParams.maxConcurrentJobs || undefined
    });

    const updateUpload = (id: string, update: Partial<LocalUpload|RemoteUpload>) => {
        setUploads(prev =>
            prev.map(upload => upload.id === id ? {...upload, ...update} : upload))
    }

    const disableAudioExtraction = () => {
        audioExtractionEnabled.current = false
        uploads.filter(up => up.isAudioOnly)
            .forEach(up => transitionAudioUploadToVideo(up as LocalUpload))

        updateUserSetting('extractAudioClientSide', false)
            .catch(() => toast.error('Failed to disable audio extraction'))
            .then(() => toast('Audio extraction disabled. You can always re-enable it from the user settings.'))
    }

    useEffect(() => {
        if (show) {
            FFmpeg.preload().catch(e => console.error(e))
        }
    }, [show]);

    useEffect(() => {
        if(!show) {
            setSubmitting(false)
            setSubmissionError('')
            setUploads([])
            setShowRemoteUploadModal(false)
        }
    }, [show]);

    useEffect(() => {
        if (getRootProps().onDrop) {
            triggerFileDrop.current = getRootProps().onDrop
        }
    }, [getRootProps]);

    useEffect(() => {
        const storedLanguage = localStorage.getItem(LANGUAGE_KEY)
        const storedMethod = localStorage.getItem(METHOD_KEY)
        if (storedLanguage) {
            setLanguage(storedLanguage)
        }
        if (storedMethod) {
            setMethod(
                storedMethod === ExtractionMethod.AUDIO ? ExtractionMethod.AUDIO : ExtractionMethod.OCR
            )
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(LANGUAGE_KEY, language)
        localStorage.setItem(METHOD_KEY, method)
    }, [language, method]);

    useEffect(() => {
        uploads.forEach(upload => {
            if('file' in upload) {
                if(method === ExtractionMethod.OCR) {
                    if(upload.isAudioOnly && upload.originalFile) {
                        return transitionAudioUploadToVideo(upload)
                    }
                } else  {
                    const eta = uploadEta[upload.id]?.etaSeconds || 0
                    if(
                        !upload.isAudioOnly &&
                        audioExtractionEnabled.current &&
                        !audioExtractor.running() &&
                        eta >= AUDIO_ETA_THRESHOLD &&
                        !disableAudioExtractions.has(upload.id)
                    ){
                        return transitionVideoUploadToAudio(upload)
                    }
                }
            }
            if(upload.progress >= 1) {
                validateVideoDuration(upload.id)
            }
        })
    }, [method])

    useEffect(() => {
        if(!(language in {...languageOptions.common, ...languageOptions[method]}) && !isLoadingLang) {
            setLanguage(DEFAULT_LANGUAGE)
        }
    }, [method]);

    useEffect(() => {
        if (initialFiles?.length) {
            const dataTransfer = new DataTransfer();
            initialFiles.forEach(file => dataTransfer.items.add(file))
            const event = new DragEvent("drop", {
                bubbles: true,
                cancelable: true,
                dataTransfer,
            });
            // @ts-ignore
            event.persist = () => {
            };
            // @ts-ignore
            triggerFileDrop.current(event)
        }
    }, [initialFiles]);

    useEffect(() => {
        if(initialUpload) {
            setUploads(prev => prev.concat(initialUpload))
            validateVideoDuration(initialUpload.id)
        }
    }, [initialUpload]);

    useEffect(() => {
        const newFiles = acceptedFiles
            .filter(file => !uploads.some(upload => "file" in upload && isFileSame(upload.file, file)))
            .slice(0, multiple ? acceptedFiles.length : 1)
            .map(file => ({
                file,
                fileName: file.name,
                fileSize: file.size,
                progress: 0,
                id: uuidv4(),
                options: {},
                isAudioOnly: isAudioFile(file),
                abortController: new AbortController()
            }))

        if(newFiles.length && !multiple && uploads.length > 0) {
            showMultipleUploadError();
        } else {
            if(newFiles.some(item => item.isAudioOnly)) {
                setMethod(ExtractionMethod.AUDIO)
            }
            newFiles.forEach(upload => {
                window.file_cache[upload.id] = upload.file
                startUpload(upload)
            })
            setUploads(prev => prev.concat(newFiles))
        }
    }, [acceptedFiles]);

    function isFileSame(file1: File, file2: File) {
        return file1.name === file2.name &&
            file1.size === file2.size &&
            file1.lastModified === file2.lastModified
    }

    function showMultipleUploadError() {
        toast.error('Trial version only allows one file at a time. To upload multiple files, please purchase credits.')
    }

    useEffect(() => {
        if (fileRejections.length > 0) {
            const code = fileRejections[0].errors[0].code;
            if (code === 'file-too-large') {
                toast.error(`File is too large. Max file size is ${bytesToSize(extractorParams.maxFileSize)}`);
            }
        }
    }, [fileRejections]);

    useEffect(() => {
        if (
            submitting &&
            uploads.length > 0 &&
            uploads.every(upload => videoDurations[upload.id] !== undefined)
        ) {
            const items : JobDetails[] = uploads.map(upload => (
                {
                    fileName: upload.fileName,
                    fileSize: upload.fileSize,
                    id: upload.id,
                    language,
                    method: method as ExtractionMethod.OCR | ExtractionMethod.AUDIO,
                    extractionParams: method === ExtractionMethod.OCR && Object.keys(upload.options).length ?
                        {
                            subtitlePosition: upload.options.position || DEFAULT_SUBTITLE_POSITION,
                            ...(upload.options.yOffsetRatio ? {yOffsetRatio: upload.options.yOffsetRatio} : {}),
                            ...(upload.options.softCodedSubtitles === false ? {ignoreSoftCodedSubtitles: true} : {})
                        } :
                        {},
                    metaData: {
                        source: 'file' in upload ? 'local' : 'remote',
                        ...'file' in upload ? {uploadDuration: upload.uploadDuration} : {},
                        ...'url' in upload ? { host: (new URL(upload.url).host) } : {},
                        ... upload.isAudioOnly && 'originalFile' in upload ? {
                            'extractedAudio' : true,
                            originalFileSize: 'originalFile' in upload && upload.originalFile?.size
                        } : {},
                    }
                }
            ));

            if(
                method === ExtractionMethod.OCR &&
                uploads.some(u => u.isAudioOnly)
            ) {
                setSubmitting(false)
                toast.error('Audio file(s) detected in Vision mode. ' +
                    'Please remove any audio files or switch to Audio extraction mode to process your upload.')
            } else {
                submitUploads(items)
            }
        }
    }, [submitting, videoDurations]);

    function submitUploads(items: JobDetails[]) {
        submit(items).then(errors => {
            if (Object.keys(errors).length > 0) {
                const totalSuccess = uploads.length - Object.keys(errors).length
                const balanceErrors = Object.values(errors).filter(e => e instanceof InsufficientCreditsException)
                setSubmissionError(
                    totalSuccess === 0 ? 'Failed to submit videos. Please try again.' :
                    `Failed to submit ${Object.keys(errors).length} out of ${uploads.length} videos. Successfully submitted ${totalSuccess} videos. Please try again.`
                )
                setSubmitting(false)
                setUploads(prev =>
                    prev.filter(upload => errors[upload.id])
                        .map(upload => ({...upload, error: errors[upload.id].message}))
                )
                if(balanceErrors.length > 0) {
                    setCredits(balanceErrors[0].currentBalance)
                    setShowCreditPurchaseModal(true)
                }
            }
        });
    }

    function transitionAudioUploadToVideo(upload: LocalUpload) {
        upload.abortController.abort()
        upload.abortController = new AbortController()
        upload.file = upload.originalFile!
        updateUpload(upload.id, { ...upload, isAudioOnly: false, step: 'uploading', progress: 0 })
        startUpload(upload)
    }

    function transitionVideoUploadToAudio(upload: LocalUpload) {
        upload.abortController.abort()
        upload.originalFile = upload.file
        upload.abortController = new AbortController()
        const update: LocalUpload = { ...upload, isAudioOnly: true, step: 'extracting', progress: 0 };
        updateUpload(upload.id, update)
        setUploadEta(prev => {
            delete prev[upload.id]
            return prev
        })
        startAudioUpload(update)
    }

    function handleOptionsChange(options: Record<string, any>) {
        options = Object.fromEntries(Object.entries(options).filter(([_, value]) => value !== undefined))
        setUploads(prev =>
            prev.map(upload => upload.id === advancedSettingsId ? {
                ...upload,
                options
            } : upload)
        )
    }

    async function validateVideoDuration(id: string) {
        try {
            const duration = await getVideoDuration(id)
            setVideoDurations(prev => ({...prev, [id]: duration}))
            updateUpload(id, {error: ''})
        } catch (err: any) {
            updateUpload(id, {error: err.message})
        }
    }

    async function startAudioUpload(upload: LocalUpload) {
        try {
            const signal = upload.abortController.signal
            const start = Date.now()
            const audioData = await audioExtractor.extractAudioAsMp3(
                upload.file,
                signal,
                (progress) => {
                    if(!signal.aborted) {
                        updateUpload(upload.id, {progress})
                    }
            });
            const end = Date.now();
            const audioFile = new File([audioData], replaceFileExtension(upload.file.name, 'mp3'), {type: 'audio/mp3'})
            const audioUpload : LocalUpload = {
                ...upload,
                uploadDuration: Math.round((end - start) / 1000),
                file: audioFile,
                fileName: audioFile.name,
                originalFile: upload.file,
                step: 'uploading'
            }
            updateUpload(upload.id, audioUpload)
            await startUpload(audioUpload)
        } catch (err: any) {
            console.error(err)
            if(err.name !== 'AbortError') {
                setDisableAudioExtractions(prev => prev.add(upload.id))
                transitionAudioUploadToVideo(upload)
            }
        }
    }

    async function startUpload(upload: LocalUpload) {
        const {awsParams} = extractorParams
        try {
            let etaSamples = 0
            const signal = upload.abortController.signal
            const start = Date.now()
            await uploadFile({
                signerUrl: route('upload.prepare'),
                file: upload.file,
                name: upload.id,
                partSize: extractorParams.maxFilePartSize,
                maxFileSize: extractorParams.maxFileSize,
                maxConcurrentParts: extractorParams.maxConcurrentPartUploads,
                signal: upload.abortController.signal,
                awsBucket: awsParams.awsBucket,
                awsKey: awsParams.awsAccessKeyId,
                awsRegion: awsParams.awsRegion,
                progressCallback: (percentage, secondsLeft) => {
                    if(signal.aborted) {
                        return;
                    }
                    if(secondsLeft !== undefined) {
                        etaSamples++
                        if(
                            audioExtractionEnabled.current &&
                            !upload.isAudioOnly &&
                            !audioExtractor.running() &&
                            method === ExtractionMethod.AUDIO &&
                            etaSamples >= 4 &&
                            secondsLeft > AUDIO_ETA_THRESHOLD &&
                            !disableAudioExtractions.has(upload.id)
                        ) {
                            return transitionVideoUploadToAudio(upload)
                        }
                        const eta = {etaSeconds: secondsLeft, lastUpdate: Date.now()};
                        setUploadEta(prev => ({
                            ...prev,
                            [upload.id]: eta.lastUpdate - (prev[upload.id]?.lastUpdate || 0) > ETA_UPDATE_INTERVAL ? eta : prev[upload.id]
                        }))
                    }
                    updateUpload(upload.id, {progress: percentage})
                }
            });
            const duration = Math.round((Date.now() - start) / 1000)
            updateUpload(upload.id, { uploadDuration: (upload.uploadDuration || 0) + duration })
            validateVideoDuration(upload.id)
        } catch (err: any) {
            const message = err.message || err
            if (message.toString().includes('aborted the upload')) {
                return
            }
            toast.error(typeof message === 'string' ? message : JSON.stringify(message))
            setUploads(prev => prev.filter(up => up.id !== upload.id))
        }
    }

    function cancelUploads() {
        uploads.forEach(upload => upload.abortController?.abort())
        setUploads([])
    }

    function cancelUpload(upload: Upload) {
        upload.abortController?.abort()
        setUploads(prev => prev.filter(up => up.id !== upload.id))
        setSubmissionError('')
    }

    function handleClose() {
        if (!uploads.length || confirm('Are you sure you want to cancel the uploads?')) {
            cancelUploads()
            onClose()
        }
    }

    function getLanguageOptions() {
        const options = {...languageOptions.common, ...languageOptions[method]};
        return Object.entries(options)
            .sort(([a], [b]) => {
                if (a === DEFAULT_LANGUAGE) return -1;
                if (b === DEFAULT_LANGUAGE) return 1;
                return a.localeCompare(b);
            })
            .map(([label, value]) => (
            {label, value}
        ))
    }

    const isFreeTrial = !multiple;
    const isUploadsComplete = uploads.every(upload =>
        upload.progress === 1 &&
        ('url' in upload || upload.step !== 'extracting')
    )
    const submitDisabled = (uploads.length > 0 && !isUploadsComplete) || submitting
    const currSettingsUpload = uploads.find(up => up.id === advancedSettingsId)
    return (
        <Modal maxWidth='xl' show={show} onClose={handleClose}>
            <div id="default-modal" className="w-full">
                <RemoteUploadModal
                    show={showRemoteUploadModal}
                    onClose={() => setShowRemoteUploadModal(false)}
                    onUploadComplete={upload => {
                        setUploads(prev => prev.concat(upload))
                        setShowRemoteUploadModal(false)
                        validateVideoDuration(upload.id)
                        toast.success('Video uploaded successfully')
                    }}
                />
                <AdvancedSettingsModal
                    show={advancedSettingsId != null}
                    initSettings={currSettingsUpload?.options as AdvancedSettings | undefined}
                    videoFile={(uploads.find(up => up.id === advancedSettingsId) as LocalUpload)?.file}
                    onSave={({position, yOffsetRatio, softCodedSubtitles}) => {
                        handleOptionsChange({
                            position: position !== DEFAULT_SUBTITLE_POSITION ? position : undefined,
                            yOffsetRatio: position === 'custom' ? yOffsetRatio : undefined,
                            softCodedSubtitles
                        })
                    }}
                    onClose={() => setAdvancedSettingsId(null)}
                />
                <PurchaseModal
                    show={showCreditPurchaseModal}
                    onCompleted={async () => {
                        setShowCreditPurchaseModal(false)
                        const currentCredits = await onCreditRefresh()
                        if(totalDurationSeconds / 60 <= currentCredits) {
                            setSubmitting(true)
                        }
                    }}
                    message={`You have ${formatMinutes(credits)} of extraction credits remaining, but need ${formatMinutes(totalDurationSeconds / 60)} to process the selected videos.`}
                    onClose={() => setShowCreditPurchaseModal(false)}
                    prices={extractorParams.pricing}
                />
                <div className="flex items-center p-4 md:p-7">
                    <div className="w-8 h-8"></div>
                    <div className="flex-grow flex justify-center">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Extract Subtitles
                        </h3>
                    </div>
                    <button type="button"
                            onClick={handleClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center !outline-none"
                          >
                        <CloseIcon/>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-10 pb-6">
                    <div {...getRootProps({className: 'dropzone flex items-center justify-center w-full'})}>
                        <label htmlFor="dropzone-file"
                               className={`flex transition-all flex-col items-center justify-center w-full ${uploads.length > 0 ? 'h-50' : 'h-60'} rounded-lg border-2 border-gray-300 border-dashed  bg-gray-50 hover:bg-gray-100  cursor-pointer`}>
                            <div className="flex flex-col items-center justify-center w-full  pt-5 pb-6">
                                <svg className="w-8 h-8 mb-4 text-gray-500 "
                                     aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 text-center"><span
                                    className="font-semibold">Click to upload</span> <span>or drag and drop</span></p>
                                <p className="text-xs text-gray-500 mx-5 text-center">Video or audio files up to {bytesToSize(extractorParams.maxFileSize)} in size</p>
                                {
                                    !isFreeTrial && (
                                        <button
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation()
                                            setShowRemoteUploadModal(true)
                                        }}
                                        className="px-4 py-2 me-2  duration-150 text-sm  text-black focus:outline-none rounded-md border border-gray-300 hover:bg-white hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 mt-5 bg-none">
                                        <FontAwesomeIcon icon={faLink} size="sm" className="mr-2"/> <span>Upload from URL</span>
                                    </button>
                                    )
                                }

                            </div>

                        </label>
                    </div>


                    <div
                        className={`flex flex-col mt-8 bg-white border shadow-sm rounded-md ${!uploads.length ? 'hidden' : ''}`}>

                        <div className="p-4 md:p-5 space-y-7">
                            {uploads.map((upload, idx) => (
                                <UploadItem
                                    upload={upload}
                                    method={method}
                                    showAdvancedSettings={() => setAdvancedSettingsId(upload.id)}
                                    cancelUpload={() => cancelUpload(upload)}
                                    uploadEta={uploadEta[upload.id]?.etaSeconds}
                                    fileDuration={videoDurations[upload.id]}
                                    disableAudioExtraction={disableAudioExtraction}
                                />))
                            }
                        </div>

                    </div>


                </div>
                <div className="px-10 pb-4 pt-0">
                    <label id="aria-label" htmlFor="lang-input" className="text-sm">
                        Language Of Subtitles
                    </label>
                    <div className="text-left mt-2 ">
                        <Select
                            key={`${method}-${language}`}
                            // https://github.com/JedWatson/react-select/issues/5816#issuecomment-2120595563
                            ariaLiveMessages={{guidance: () => '', onChange: () => '', onFilter: () => '', onFocus: () => ''}}
                            menuPlacement="top"
                            inputId="lang-input"
                            onChange={(e) => setLanguage(e!.value)}
                            defaultValue={{label: language, value: language}}
                            options={getLanguageOptions()}/>
                    </div>
                </div>

                <div className="px-10 pb-8 pt-0">
                    <label id="aria-label" htmlFor="lang-input" className="text-sm">
                        Extraction Method
                    </label>
                    <div className="text-left mt-2 ">
                        <div className="flex w-full">
                            <Popover
                                delay={200}
                                trigger="hover"
                                placement="top"
                                aria-labelledby="default-popover"
                                content={
                                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                                        <div className="px-3 py-2">
                                            <p>Extracts visible, hardcoded subtitles using OCR. <b>Works only if subtitles are present on screen.</b></p>
                                        </div>
                                    </div>
                                }
                            >
                                <button type="button"
                                        onClick={() => setMethod(ExtractionMethod.OCR)}
                                        className={`px-4 grow py-3 text-sm font-medium flex flex-col items-center flex-1
                                       border-2 rounded  hover:bg-gray-100 hover:text-blue-700 ${method === ExtractionMethod.OCR ? 'border-blue-600 text-blue-600 ' : 'bg-white border-gray-200 text-gray-900'}`}>
                                    <FontAwesomeIcon icon={faEye} className="text-blue-700 mb-1.5" size="lg"/>
                                    <span className="font-bold text-md mb-0.5">Vision</span>
                                    <span className="text-xs text-gray-500">Extract hard-coded subtitles</span>
                                </button>
                            </Popover>


                            <div className="w-3"></div>

                            <Popover
                                delay={200}
                                trigger="hover"
                                placement="top"
                                aria-labelledby="default-popover"
                                content={
                                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                                        <div className="px-3 py-2">
                                            <p>Extracts subtitles from spoken dialogue. Best for videos without visible subtitles.</p>
                                        </div>
                                    </div>
                                }
                            >
                                <button type="button"
                                        onClick={() => setMethod(ExtractionMethod.AUDIO)}
                                        className={`px-4 grow py-3 text-sm font-medium flex flex-col items-center flex-1
                                        border-2 rounded hover:bg-gray-100 hover:text-blue-700 ${method === ExtractionMethod.AUDIO ? '  border-blue-600 text-blue-600 ' : 'bg-white border-gray-200 text-gray-900'}`}>
                                    <FontAwesomeIcon icon={faVolumeHigh} className="text-blue-700 mb-1.5" size="lg"/>
                                    <span className="font-bold text-md mb-0.5">Audio</span>
                                    <span className="text-xs text-gray-500">Extract subtitles from speech</span>
                                </button>
                            </Popover>


                        </div>

                    </div>
                </div>

                <div className="px-10 pb-8">
                    {submissionError !== '' && (
                        <div
                            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 -mt-4"
                            role="alert">
                            <span className="font-medium">Error!</span> {submissionError}
                        </div>
                    )}
                    <button onClick={() => setSubmitting(true)}
                            disabled={submitDisabled}
                            className={`transition-all w-full py-3 rounded-lg  ${submitDisabled ? 'bg-blue-300 text-white' : 'text-white bg-blue-600 hover:bg-blue-700'}`}>
                        <span className="flex justify-center items-center">
                            <span>{submitDisabled ? (submitting ? 'Submitting' : 'Uploading') : 'Start Extraction'}</span>
                            {(submitDisabled) && <Spinner size={4} className="ml-2 fill-white"/>}
                        </span>
                    </button>
                </div>
            </div>
        </Modal>
    )
}
