import {useEffect, useMemo, useState} from "react";
import Modal from "@/Components/Modal";
import axios from "axios";
import Spinner from "@/Components/Spinner";
import PayPalButton from "@/Components/PayPalButton";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import {PaymentStatus} from "@/types/enums";

export type PurchaseModalProps = {
    show: boolean,
    onClose: () => void,
    prices?: Record<string, number>
    onCompleted?: () => void,
    message?: string,
}


export default function PurchaseModal({show, onClose, prices: initPrices, message, onCompleted }: PurchaseModalProps) {
    const [prices, setPrices] = useState(initPrices);
    const [selectedHours, setSelectedHours] = useState(10);
    const [submitting, setIsSubmitting] = useState('');
    const isAsync = onCompleted !== undefined;
    const total = useMemo(() => {
        return prices ? selectedHours * prices[selectedHours] : 0;
    }, [selectedHours, prices]);

    const getPrices = async () => {
        const result = await axios.get(route('credit.pricing'));
        setPrices(result.data.pricing);
    }

    useEffect(() => {
        if(show && !prices) {
            getPrices();
        }
    }, [show]);

    const initPaymentSession = async (payment_type: string) => {
        setIsSubmitting(payment_type)
        const origin = route().current();
        const params = { hours: selectedHours, origin, "async": isAsync, payment_type: payment_type };
        const result = await axios.post(route('payment.charge'), params); // todo add error handling
        const { url, session_id } = result.data;
        if(isAsync) {
            let childWindow = window.open(url, '_blank');
            if(childWindow) {
                await waitForPaymentFlow(childWindow);
                const status = await checkPaymentStatus(session_id) as PaymentStatus;
                if(status === PaymentStatus.COMPLETED) {
                    toast.success('Payment completed');
                    onCompleted();
                } else if(status === PaymentStatus.PENDING) {
                    toast.success('Payment pending. Refresh the page to see the updated status');
                }
                setIsSubmitting('')
            } else {
                window.location.href = url;
            }
        } else {
            window.location.href = url;
        }
    }

    const waitForPaymentFlow = async (childWindow: Window): Promise<void> => {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                const isClosed = childWindow.closed;
                if(isClosed) {
                    clearInterval(interval);
                    resolve()
                }
            }, 1000);
        })
    }

    const checkPaymentStatus = async (sessionId: string) => {
        const url = route('payment.status', {session_id: sessionId});
        const result = await axios.get(url);
        return result.data.status;
    }

    useEffect(() => {
        setIsSubmitting('')
    }, [show]);

    return (
        <Modal maxWidth='sm' show={show} onClose={onClose}>
            <div id="default-modal" className="w-full p-2">
                <div className="flex items-center">
                    <div className="w-8 h-8"></div>
                    <div className="flex-grow flex justify-center">
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                {
                    message && (
                        <div
                            className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 text-center" role="alert">
                            <span className="text-sm">{message}</span>
                        </div>)
}
    <div className="flex flex-col items-center justify-center">
        <h3 className="text-xl text-center font-semibold text-gray-900">
            Buy more credits
        </h3>
                    <h4 className="font-bold text-2xl mt-2">${total.toFixed(2)}</h4>
                </div>



                <hr className="my-5 mx-5"/>
                <div className="bg-white mt-5 mb-1 p-6 rounded-lg  w-full mx-auto">
                    {
                        Object.keys(prices || {}).map((key) => (
                            <div key={key} className="flex mb-4 items-center">
                                <div className="flex items-center h-5">
                                    <input
                                        checked={selectedHours === parseInt(key)}
                                        onChange={() => setSelectedHours(parseInt(key))}
                                        id={`radio-${key}`}
                                        aria-describedby={`helper-radio-text-${key}`}
                                        type="radio"
                                        name="priceOption"
                                        value={key}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                </div>
                                <div className="ml-2 text-sm flex justify-between w-full">
                                    <label
                                        htmlFor={`radio-${key}`}
                                        className="font-medium text-gray-900 dark:text-gray-300 flex-grow"
                                    >
                                        {key} Extraction hours
                                    </label>
                                    <p
                                        id={`helper-radio-text-${key}`}
                                        className="text-xs font-normal text-gray-500 dark:text-gray-300  text-left"
                                    >
                                        ${prices![key].toFixed(2)} / hour
                                    </p>
                                </div>
                            </div>
                        ))

                    }
                    <div className="flex justify-between items-center mt-8">
                        <button
                            disabled={submitting !== ''}
                            onClick={() => initPaymentSession('stripe')}
                            className={`w-full mr-3 flex justify-center items-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ${submitting ? 'bg-gray-500 hover:bg-gray-500' : ''}`}>
                            <FontAwesomeIcon
                                className="text-gray-300 w-4 h-4 pr-2 py-1"
                                icon={faCreditCard}/>
                            <span>Credit Card</span>{submitting === 'stripe' && <Spinner className="ml-2"/>}
                        </button>
                        <PayPalButton
                            disabled={submitting !== ''}
                            spinner={submitting === 'paypal'}
                            onClick={() => initPaymentSession('paypal')}
                        />
                    </div>




                </div>


            </div>
        </Modal>
    )
}
