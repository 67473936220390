
import {useEffect} from "react";
import toast from "react-hot-toast";
import {PageProps, SessionFlash} from "@/types";

export default function useSessionToast(flash?: SessionFlash) {
    useEffect(() => {
        if(flash) {
            if (flash.success) {
                toast.success(flash.success);
            } else if (flash.error) {
                toast.error(flash.error);
            } else if (flash.warning) {
                toast(flash.warning, {icon: '⚠️'})
            }
        }
    }, [flash]);

    return null;
}
