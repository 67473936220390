import {FormEventHandler, useEffect} from 'react';
import Checkbox from '@/Components/Checkbox';
import GuestLayoutCompact from '@/Layouts/Guest/GuestLayoutCompact';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import {Head, Link, useForm, usePage} from '@inertiajs/react';
import GoogleSignInButton from "@/Components/GoogleSignInButton";
import {PageProps} from "@/types";
import useSessionToast from "@/utils/useSessionToast";

type LoginPageProps = {
    status?: string;
    canResetPassword: boolean;
    googleOauthEnabled: boolean;
}

export default function Login({status, canResetPassword, googleOauthEnabled}: PageProps<LoginPageProps>) {
    const {data, setData, post, processing, errors, reset} = useForm({
        email: '',
        password: '',
        remember: true,
    });

    const { sessionFlash } = usePage<PageProps>().props
    useSessionToast(sessionFlash);

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const submit: FormEventHandler = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    return (
        <GuestLayoutCompact>
            <Head title="Log in">
                <meta name="description" content="Log in to access your account."/>
            </Head>
            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}
            {
                googleOauthEnabled && (
                    <>
                        <div className="flex justify-center mt-5 ">
                            <a href={route('auth.redirect')} className="w-full block">
                                <GoogleSignInButton/>
                            </a>
                        </div>
                        <hr className="my-9"/>
                    </>
                )
            }
            <form onSubmit={submit}>
                <div>
                    <InputLabel htmlFor="email" value="Email"/>

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        isFocused={true}
                        required={true}
                        onChange={(e) => setData('email', e.target.value)}
                    />

                    <InputError message={errors.email} className="mt-2"/>
                </div>

                <div className="mt-4">
                    <InputLabel htmlFor="password" value="Password"/>

                    <TextInput
                        required={true}
                        id="password"
                        type="password"
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        onChange={(e) => setData('password', e.target.value)}
                    />

                    <InputError message={errors.password} className="mt-2"/>
                </div>

                <div className="block mt-4">
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData('remember', e.target.checked)}
                        />
                        <span className="ms-2 text-sm text-gray-600">Remember me</span>
                        <div className="grow"/>
                        {canResetPassword && (
                            <Link
                                href={route('password.request')}
                                className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Forgot your password?
                            </Link>
                        )}
                    </label>

                </div>


                <div className="flex items-center justify-center mt-6">

                    <PrimaryButton className="w-full !me-0" disabled={processing}>
                        Log in
                    </PrimaryButton>
                </div>

                <div className="text-sm text-gray-600 text-center mt-5 mb-1">
                    Dont have an account? <Link
                    href={route('register')}
                    className="underline  text-blue-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Sign up
                </Link>
                </div>
            </form>
        </GuestLayoutCompact>
    );
}
