import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from "@/types";
import {useState} from "react";
import {Head} from "@inertiajs/react";
import {faqItems} from "@/Pages/Faq/faqItems";
import GuestLayout from "@/Layouts/Guest/GuestLayout";
import PageLayout from "@/Layouts/PageLayout";

type FaqsProps = {
    params: Record<string, string>;
}

export default function Faqs({auth, sessionFlash, params }: PageProps<FaqsProps>) {
    return (
        <PageLayout header="Frequently Asked Questions">
            {(authenticated) => (
                <>
                    {
                        authenticated ?
                            <div className="pt-12 max-w-7xl mx-auto px-6 lg:px-8 space-y-6">
                                <div className="p-4 sm:p-8 bg-white shadow sm:rounded-lg">
                                    <FaqsContent params={params}/>
                                </div>
                            </div>
                            :
                            <FaqsContent params={params}/>
                    }
                </>
            )}
        </PageLayout>
    )
}

export function FaqsContent({ params } : FaqsProps) {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const replaceParams = (str: string) => {
        return str.replace(/\${(\w+)}/g, (_, key) => params[key]);
    }

    return (
        <div className="container mx-auto py-5 md:py-6 px-4 sm:px-6 lg:px-8">
            <Head title="FAQs">
                <meta name="description" content="Frequently asked questions"/>
            </Head>
            <div className="space-y-4">
                {faqItems.map((faq, index) => (
                    <div key={index} className={`${index < faqItems.length-1 ? 'border-b' : ''} border-gray-200 pb-4`}>
                        <button
                            className="flex justify-between items-center w-full text-left font-medium text-gray-900 mb-2"
                            onClick={() => toggleAccordion(index)}
                        >
                            <span>{faq.question}</span>
                            <svg
                                className={`w-5 h-5 ml-2 transition-transform duration-300 ease-in-out ${
                                    activeIndex === index ? 'transform rotate-180' : ''
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>
                        <div
                            className={`text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                activeIndex === index ? 'max-h-96' : 'max-h-0'
                            }`}
                        >
                            <div className="pt-2">{replaceParams(faq.answer)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
