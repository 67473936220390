import {toBlobURL} from "@ffmpeg/util";
import {FFmpeg} from "@ffmpeg/ffmpeg";
import {getLanguageName} from "@/utils/textFormatting";

export type VideoMetaData = {
    duration: number,
    subtitles: SubtitleTrack[],
    width: number,
    height: number,
}

export type SubtitleTrack = {
    streamNumber: number,
    language: string,
    codec: SubtitleCodec,
}

export type SubtitleCodec = 'subrip' | 'ass' | 'ssa' | 'mov_text' | 'hdmv_pgs_subtitle' | 'dvd_subtitle';

type FFmpegConfig = {
    coreURL: string,
    wasmURL: string,
    classWorkerURL: string
}

const DURATION_PATTERN = /Duration:\s*([0-9]{2}):([0-9]{2}):([0-9]{2}.[0-9]{0,2})/m;
const DIMENSIONS_PATTERN = /Stream #0:[^:]+: Video: .+, (\d+)x(\d+)/;
const SUBTITLE_PATTERN = /Stream #0:(\d+)[^(]*(?:\((\w+)\))?: Subtitle: (\w+)/g;

export default class FFmpegClient extends FFmpeg {

    private readonly _load: (args: any) => Promise<boolean>;
    private static config: Promise<FFmpegConfig> | null = null;

    public constructor() {
        super();
        this._load = this.load;
        this.load = () => {
            return FFmpegClient.getConfig().then(this._load);
        }
    }

    private static async loadConfig(): Promise<FFmpegConfig> {
        const coreURL = await toBlobURL(`/ffmpeg/ffmpeg-core.js`, 'text/javascript');
        const wasmURL = await toBlobURL(`/ffmpeg/ffmpeg-core.wasm`, 'application/wasm');
        const classWorkerURL = await toBlobURL(`/ffmpeg/ffmpeg-worker.js`, 'text/javascript');
        return { coreURL, wasmURL, classWorkerURL };
    }

    private static getConfig() {
        if(!FFmpegClient.config) {
            FFmpegClient.config = FFmpegClient.loadConfig();
        }
        return FFmpegClient.config;
    }

    public static async preload() {
        await FFmpegClient.getConfig();
    }

    protected getMetadata(inputFile: string): Promise<string> {
        return new Promise((resolve, rej) => {
            let log = '';
            let metadataLogger = (e: any) => {
                log += e.message;
                if (e.message.indexOf('Aborted()') > -1) {
                    this.off('log', metadataLogger);
                    resolve(log);
                }
            };
            this.on('log', metadataLogger);
            this.exec(["-i", inputFile]).catch(rej);
        });
    }

    public async getVideoMetaData(inputFile: string): Promise<VideoMetaData> {
        const metadata = await this.getMetadata(inputFile);

        const subtitles: SubtitleTrack[] = [];
        let subtitleMatch;
        while ((subtitleMatch = SUBTITLE_PATTERN.exec(metadata)) !== null) {
            subtitles.push({
                streamNumber: parseInt(subtitleMatch[1]),
                language: subtitleMatch[2] ? getLanguageName(subtitleMatch[2]) : 'Unknown',
                codec: subtitleMatch[3] as SubtitleCodec,
            });
        }

        const dimensionsMatch = DIMENSIONS_PATTERN.exec(metadata);
        const width = dimensionsMatch ? parseInt(dimensionsMatch[1]) : 0;
        const height = dimensionsMatch ? parseInt(dimensionsMatch[2]) : 0;
        const durationMatch = DURATION_PATTERN.exec(metadata);
        const duration = durationMatch ? (parseInt(durationMatch[1]) * 3600) + (parseInt(durationMatch[2]) * 60) + (parseInt(durationMatch[3])) : 0;
        return { duration, subtitles, width, height };
    }
}
