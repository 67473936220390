import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head} from "@inertiajs/react";
import PrimaryButton from "@/Components/PrimaryButton";
import Layout from "@/Pages/Api/ApiPageLayout";
import ApiOverview from "@/Pages/Api/ApiOverview";


export default function (props: PageProps) {
    return (
        <>
            <Layout>
                <div className="flex justify-center items-center h-full my-2">
                    <ApiOverview user={props.auth.user}/>
                </div>
            </Layout>
        </>
    )
}
