import AuthenticatedLayout from "@/Layouts/Auth/AuthenticatedLayout";
import {Head, router} from "@inertiajs/react";
import {PageProps} from "@/types";
import TransactionsTable from "@/Pages/Billing/Partials/TransactionsTable";
import PendingPaymentsTable from "@/Pages/Billing/Partials/PendingPaymentsTable";
import axios from "axios";
import toast from "react-hot-toast";
import Credits from "@/Pages/Dashboard/Partials/Credits";

type BillingProps = {
    pendingPayments: any[],
    creditBalance: number,
    pricing: Record<string, number>
}

export default function Billing({auth, pendingPayments, creditBalance, pricing, sessionFlash}: PageProps<BillingProps>) {
    const cancelPayment = (paymentId: string) => {
        axios.put(route('payment.cancel-pending', paymentId))
            .then(() => {
                toast.success('Payment cancelled');
                router.reload({preserveScroll: true})
            }).catch(err => {
                const message = err.response?.data?.message || 'An error occurred while cancelling the payment';
                toast.error(message);
        });
    }

    return (
        <AuthenticatedLayout
            header={<h2 className="font-semibold text-xl text-gray-800 leading-tight">Billing</h2>}
        >
            <Head title="Billing"/>
            <div className="mx-4 md:mx-0">
            <div className="pt-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
                    <div className=" py-6 px-6 bg-white border border-gray-200 rounded-lg shadow-sm">
                        <Credits
                            user={auth.user}
                            inline={true}
                            prices={pricing}
                            credits={creditBalance}
                        />
                    </div>
                </div>
            </div>
                {
                    pendingPayments.length >  0 && (
                        <div className="pt-8">
                            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
                                <div className=" py-6 bg-white border border-gray-200 rounded-lg shadow-sm  overflow-x-auto">
                                    <h5 className="mb-2 text-xl font-bold text-gray-900 ml-4 mb-8">Pending
                                        Payments</h5>
                                    <PendingPaymentsTable pendingPayments={pendingPayments}
                                                          cancelPayment={cancelPayment}/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="py-8">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6">

                        <div className=" py-6 bg-white border border-gray-200 rounded-lg shadow-sm">
                            <h5 className="mb-2 text-xl font-bold text-gray-900 ml-4 mb-8">History</h5>
                            <div className="  overflow-x-auto">
                            <TransactionsTable/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
)
}
