import ApplicationLogo from '@/Components/ApplicationLogo';
import {Link} from '@inertiajs/react';
import {PropsWithChildren} from 'react';
import {Toaster} from "react-hot-toast";
import Header from "@/Layouts/Guest/Header";
import {PageProps} from "@/types";
import Footer from "@/Layouts/Guest/Footer";

export default function GuestLayout({children}: PropsWithChildren) {
    return (
        <>
            <div
                className="relative sm:items-center min-h-[70vh]  bg-center bg-white selection:bg-orange-500 selection:text-white">
                <Header/>
                {children}
            </div>
            <div className="xl:px-10">
                <Footer/>
            </div>
            <Toaster/>
        </>
    );
}
