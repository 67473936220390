import moment from "moment";
import {ucFirst, formatMinutes} from "@/utils/textFormatting";
import {useEffect, useState} from "react";
import axios from "axios";
import usePaginatedSource from "@/hooks/usePaginatedSource";
import toast from "react-hot-toast";
import PaginationControl from "@/Components/PaginationControl";

export type TransactionsTableProps = {
    transactions: any[]
    totalPages: number
    totalRecords: number
    nextPage: () => void
}

export default function () {
    const paginatedSource = usePaginatedSource<any>('billing.transactions', 10);
    const {
        items,
        nextPage,
        prevPage,
        pagination,
        error
    } = paginatedSource;

    useEffect(() => {
        if(error) {
            toast.error('Failed to load transactions');
        }
    }, [error]);

    return (
        <div className="">
            <table className="min-w-full leading-normal">
                <thead>
                <tr>
                    <th className="px-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Date
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Type
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Amount
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Source
                    </th>
                </tr>
                </thead>
                <tbody>
                {items.map((transaction,
                    idx) => (
                    <tr key={transaction.id}>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {moment(transaction.created_at).format('MMM Do YYYY, h:mm:ss a')}
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {ucFirst(transaction.type)}
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            <span className={`${transaction.amount > 0 ? 'text-green-500' : ''} font-semibold`}>
                                {formatMinutes(transaction.amount)}
                            </span>
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {ucFirst(transaction.type === 'debit' ?
                                (transaction.transactionable_type?.includes('Translation') ? 'Translation' : 'Extraction') :
                                transaction.source)
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="flex justify-between items-center mt-4 mx-6">
                 <span className="text-xs text-gray-800">
                    Page {pagination.currentPage} of {pagination.lastPage}
                </span>
                <PaginationControl source={paginatedSource}/>
            </div>


        </div>
    );
}
