import moment from "moment/moment";
import {ucFirst, formatCentsToDollars, formatMinutes} from "@/utils/textFormatting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";

type PendingPaymentsTableProps = {
    pendingPayments: any[]
    cancelPayment: (paymentId: string) => void
}

export default function ({pendingPayments, cancelPayment }: PendingPaymentsTableProps) {

    const handleCancellation = (payment: any) => {
        if (!confirm('Are you sure you want to cancel this payment?')) {
            return
        }
        cancelPayment(payment.id);
    }

    return (
        <div className="">
            <table className="min-w-full leading-normal">
                <thead>
                <tr>
                    <th className="px-5 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Date
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Payment Amount
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Credits
                    </th>
                    <th className="px-5 py-3  text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {pendingPayments.map((payment,
                    idx) => (
                    <tr key={payment.id}>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < pendingPayments.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {moment(payment.created_at).format('MMM Do YYYY, h:mm:ss a')}
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < pendingPayments.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {formatCentsToDollars(payment.payment_amount)}
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < pendingPayments.length - 1 ? 'border-b border-gray-200' : ''}`}>
                            {formatMinutes(payment.credit_amount)}
                        </td>
                        <td className={`px-5 py-5 bg-white text-sm ${idx < pendingPayments.length - 1 ? 'border-b border-gray-200' : ''}`}>

                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                <button type="button"
                                        onClick={() => handleCancellation(payment)}
                                        className="inline-flex items-center pr-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-red-600 ">

                                    <FontAwesomeIcon
                                        className="text-gray-300 w-4 h-4 px-2 py-1 hover:text-red-600"
                                        icon={faCircleXmark}/>
                                    Cancel
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}
