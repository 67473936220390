import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBullseye,
    faEarthAmericas,
    faGaugeHigh,
    faLanguage,
    faMagicWandSparkles,
    faShieldHalved
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "@inertiajs/react";

export default function () {
    return (
        <div className="container mx-auto px-5">
            <main className="grid lg:grid-cols-2 place-items-center pt-16 pb-0 md:pt-12 md:pb-10 ">
                <div className="py-10 md:py-6 order-1 md:block">
                    <img alt="Extracting hardcoded subtitles from video" src="/img/banner.gif"/>
                </div>
                <div className="flex flex-col items-center lg:flex-initial lg:items-start text-center lg:text-start">
                    <h1 className="text-4xl lg:text-5xl 2xl:text-6xl font-bold lg:tracking-tight xl:tracking-tighter">
                        Extract Subtitles from Videos
                    </h1>
                    <p className="text-lg mt-4 text-slate-600 max-w-xl">
                        Break language barriers. Extract hardcoded or audio-based subtitles for accurate translation.
                    </p>
                    <div className="mt-6 flex flex-col gap-3 ">
                        <a
                            className="rounded text-lg  w-fit transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5  hover:bg-gray-500 hover:text-white  border-2 border-gray-500 inline-flex  gap-1 items-center "
                            href={route('auth.redirect')}>
                            <svg className="w-4 h-4 me-2" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 48 48"
                                 style={{display: 'block'}}>
                                <path fill="#EA4335"
                                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4"
                                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05"
                                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853"
                                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                            <span className="whitespace-nowrap">Start Extracting for Free</span>
                        </a>

                        <Link
                            className="w-fit underline inline-block mx-auto lg:mx-0"
                            href={route('register')}>Sign up with email address</Link>

                    </div>
                </div>
            </main>
            <hr className="pt-5 md:pt-10"/>
            <div className="mt-5 md:mt-16 md:mt-0"><h2
                className="md:text-4xl lg:text-3xl text-lg font-bold lg:tracking-tight">
                Simplified Hardcoded Subtitle Extraction
            </h2> <p className="text-lg mt-4 text-slate-600">
                Our tool simplifies the extraction of burned-in subtitles. Upload your video, get SRT subtitles. It's
                that simple.
            </p></div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 mt-16 gap-16">
                <div className="flex gap-4 items-start">
                    <div className="mt-1rounded-full pt-2">
                        <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faBullseye}/>
                    </div>
                    <div><h3 className="font-semibold text-lg">High Accuracy</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Our AI-driven tool precisely captures and
                        extracts subtitles, ensuring accurate transcription from your videos, no matter the
                        complexity.</p></div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="mt-1rounded-full pt-2">
                        <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faMagicWandSparkles}/>
                    </div>
                    <div><h3 className="font-semibold text-lg">100% Automated</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Upload your video, and our system automatically
                        handles the subtitle extraction. It's a hands-off process for you, from start to finish.</p>
                    </div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="mt-1rounded-full pt-2">
                        <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faEarthAmericas}/>
                    </div>
                    <div><h3 className="font-semibold text-lg">Multiple Languages</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Whether it's English, Spanish, Mandarin, or any
                        other language, our tool is equipped to accurately extract subtitles from a diverse range of
                        languages.</p></div>
                </div>
                <div className="flex gap-4 items-start">
                    <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faGaugeHigh}/>
                    <div><h3 className="font-semibold text-lg">Fast</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Quickly receive your extracted subtitles. Our
                        efficient AI technology speeds up the process, delivering quality results in less time.</p>
                    </div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="mt-1rounded-full pt-2">
                        <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faLanguage}/>
                    </div>
                    <div><h3 className="font-semibold text-lg">Translation Ready</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Enjoy easier translations with our accurate
                        extractions. Subtitles are provided in SRT format, ready for precise translation.</p>
                    </div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="mt-1rounded-full pt-2">
                        <FontAwesomeIcon className="text-orange-500" size="2xl" icon={faShieldHalved}/>
                    </div>
                    <div><h3 className="font-semibold text-lg">Privacy First</h3>  <p
                        className="text-slate-500 mt-2 leading-relaxed">Your privacy is our priority. Uploaded video
                        files are securely processed and then deleted from our servers within 48 hours.</p></div>
                </div>
            </div>

        </div>
    )
}
