export default function HomePageContent() {
    return (
        <>
            <section className="bg-gray-50 py-14 my-10">
                <div className="container mx-auto space-y-4">
                    <div className="text-center">
                        <h3 className="text-2xl mb-8 font-bold">Why Choose Us?</h3>
                    </div>
                    <div className="grid md:grid-cols-3 gap-4 text-center">
                        <div className="p-4 ">
                            <h4 className="font-bold mb-2">AI-Driven Accuracy</h4>
                            <p>Utilizing advanced AI, we ensure high-precision subtitle extraction for clear,
                                quality results.</p>
                        </div>
                        <div className="p-4 ">
                            <h4 className="font-bold mb-2">Supports All Languages</h4>
                            <p>Our technology is designed to handle and accurately extract subtitles in any language
                                from your videos.</p>
                        </div>
                        <div className="p-4 ">
                            <h4 className="font-bold mb-2">Fast and Reliable</h4>
                            <p>Get your subtitles quickly without sacrificing quality, thanks to our efficient
                                AI-powered process.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-12 mb-6 px-4 md:px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="text-gray-600 text-sm">
                        <p>SubtitleExtractor.com offers a solution for extracting hardcoded or burned-in subtitles
                            from video files. Leveraging the latest advancements in AI technology, our platform is
                            uniquely equipped to handle subtitle extraction in any language with unmatched precision
                            and efficiency. Whether you're dealing with corporate presentations, educational
                            content, or entertainment media, SubtitleExtractor.com ensures that extracting subtitles
                            from your videos is a seamless and accurate process.</p>
                        <p className="mt-4">Our state-of-the-art AI algorithms are specifically designed to
                            recognize and extract subtitles that are permanently embedded in video files, commonly
                            known as hardcoded or burned-in subtitles. This robust feature makes
                            SubtitleExtractor.com an invaluable tool for professionals in various sectors, including
                            film production, broadcasting, and digital content creation, who regularly need to
                            extract readable text from their video content.</p>
                        <p className="mt-4">With SubtitleExtractor.com, you can effortlessly extract hardcoded video
                            subtitles and burned-in video subtitles, ensuring that your videos are accessible and
                            compliant with various language and accessibility standards. Our platform supports a
                            wide range of video formats, providing you with a versatile and reliable tool for all
                            your subtitle extraction needs. Embrace the power of AI with SubtitleExtractor.com and
                            transform the way you extract subtitles from your videos.</p>
                    </div>
                </div>
            </section>
        </>
    )
}
