import Modal from "@/Components/Modal";
import TextInput from "@/Components/TextInput";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import {useEffect, useMemo, useState} from "react";
import debounce from 'debounce';
import Checkbox from "@/Components/Checkbox";

export type EditNameModalProps = {
    show: boolean;
    onClose: () => void;
    name: string;
    onUpdated: (name: string) => void;
}

export default function ({ show, onClose, name, onUpdated }: EditNameModalProps) {
    const [nameVal, setNameVal] = useState(name);

    return (
        <Modal maxWidth='sm' show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center p-7">

                    <div className="flex-grow flex">
                        <h3 className="text-xl font-bold">Rename</h3>
                    </div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="flex flex-col  px-7 mb-5">
                    <InputLabel className="sm:font-bold sm:text-base" htmlFor="name" value="Name"/>
                    <TextInput
                       id="name"
                       className="w-full mb-1" value={nameVal}
                       onChange={e => setNameVal(e.target.value)}
                       isFocused={true}
                    />

                    <PrimaryButton
                        onClick={() => {
                            onUpdated(nameVal);
                        }}
                        className="mt-5 w-auto self-end mr-0">Save</PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}
