import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Modal from "@/Components/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@/Components/Icons/CloseIcon";

export interface MultiViewModalProps {
    title?: string;
    show: boolean;
    showBackButton?: boolean;
    onClose: () => void;
    children: ReactElement[];
    extras?: ReactElement;
    maxWidth?: "sm" | "md" | "lg" | "xl" | "none" | "2xl" | undefined;
    currentIdx: number;
    onBack: () => void;
}

export function MultiViewModal({
                                   maxWidth,
                                   showBackButton,
                                   extras,
                                   title,
                                   show,
                                   onClose,
                                   children,
                                   currentIdx,
                                   onBack
                               }: MultiViewModalProps) {
    const [heights, setHeights] = useState<number[]>([]);
    const childRefs = useRef<(HTMLDivElement | null)[]>([]);
    const observerRef = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        const updateHeight = (index: number) => {
            const childElement = childRefs.current[index]?.children[0] as HTMLElement;
            if (childElement) {
                setHeights(prevHeights => {
                    const newHeights = [...prevHeights];
                    newHeights[index] = childElement.scrollHeight;
                    return newHeights;
                });
            }
        };

        observerRef.current = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const index = childRefs.current.findIndex(ref => ref?.children[0] === entry.target);
                if (index !== -1) {
                    updateHeight(index);
                }
            });
        });

        childRefs.current.forEach((childRef, index) => {
            if (childRef) {
                observerRef.current?.observe(childRef.children[0]);
                updateHeight(index);
            }
        });

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [children]);

    const renderNavBar = (): JSX.Element => (
        <div className="flex items-center p-4 md:p-7">
            <div className="w-8 h-8">
                {currentIdx > 0 && (showBackButton || showBackButton === undefined) && (
                    <button type="button"
                            title="Back"
                            onClick={onBack}
                            className="bg-transparent hover:bg-gray-200 text-gray-400 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="w-4 h-4"
                            title="Back"
                        />
                    </button>
                )}
            </div>
            <div className="flex-grow flex justify-center">
                <h3 className="text-xl font-semibold text-gray-900">
                    {children[currentIdx].props.title || title || ''}
                </h3>
            </div>
            <button type="button"
                    onClick={onClose}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                    data-modal-hide="default-modal">
                <CloseIcon/>
                <span className="sr-only">Close modal</span>
            </button>
        </div>
    );

    return (
        <Modal maxWidth={maxWidth} show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                {extras && extras}
                {renderNavBar()}
                <div
                    className="screen-wrapper overflow-hidden transition-height duration-300 ease-in-out"
                    style={{ height: `${heights[currentIdx] || 'auto'}px` }}
                >
                    <div
                        className="flex transition-transform duration-300 ease-in-out"
                        style={{ transform: `translateX(-${currentIdx * 100}%)` }}
                    >
                        {children.map((child, index) => (
                            <div
                                key={index}
                                ref={el => {
                                    childRefs.current[index] = el;
                                    if (el && observerRef.current) {
                                        observerRef.current.observe(el);
                                    }
                                }}
                                className="w-full flex-shrink-0"
                            >
                                {child}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
