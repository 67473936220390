import {User} from "@/types";
import {sha256} from "js-sha256";
import {ExtractionMethod, TranslationMethod} from "@/types/enums";

export enum AnalyticsEvent {
    PURCHASE = 'purchase',
    VIEW_AI_TRANSLATE_INFO = 'view_ai_translate_info',
    TRANSLATE = 'translate',
    EXTRACTION = 'extraction',
    EXTRACTION_SUCCESS = 'extraction_success',
    PAGE_VIEW = 'page_view',
}

interface PurchaseEventParams {
    value: number;
    currency: string;
    transaction_id: string;
}

interface PageViewEventParams {
    page_location: string;
    page_title: string;
}

interface TranslateEventParams {
    language_code: string;
    language_name: string;
    method: TranslationMethod;
}

interface ExtractionEventParams {
    method: ExtractionMethod;
    batch_size: number;
}

interface ExtractionSuccessEventParams {
    method: ExtractionMethod;
}

interface viewAiTranslateInfoEventParams {
    language_name: string;
}

type EventParams = {
    [AnalyticsEvent.PURCHASE]: PurchaseEventParams;
    [AnalyticsEvent.VIEW_AI_TRANSLATE_INFO]: viewAiTranslateInfoEventParams;
    [AnalyticsEvent.TRANSLATE]: TranslateEventParams;
    [AnalyticsEvent.EXTRACTION]: ExtractionEventParams;
    [AnalyticsEvent.EXTRACTION_SUCCESS]: ExtractionSuccessEventParams;
    [AnalyticsEvent.PAGE_VIEW]: PageViewEventParams;
}

export function sendEvent<T extends AnalyticsEvent>(
    event: T,
    params: EventParams[T],
    debug = false
) {
    if(event === AnalyticsEvent.PURCHASE) {
        dispatchAdsPurchaseEvent(params as PurchaseEventParams);
    }
    gtag('event', event, {...params, debug_mode: debug });
}

function dispatchAdsPurchaseEvent(params: PurchaseEventParams) {
    gtag('event', 'conversion', {
        ...params,
        'send_to': 'AW-10849655585/aioYCKHiiLkZEKG2wrUo',
    });
}

export function setUserData(user: User) {
    gtag('set', 'user_data', {
        sha256_email_address: hashEmail(user.email),
    });
}

function hashEmail(email: string) {
    const normalizedEmail = email.trim().toLowerCase();
    return sha256(normalizedEmail);
}
