import {Head, Link} from "@inertiajs/react";
import ApplicationLogo from "@/Components/ApplicationLogo";
import {PageProps} from "@/types";
import Authenticated from "@/Layouts/Auth/AuthenticatedLayout";
import GuestLayout from "@/Layouts/Guest/GuestLayout";
import PageLayout from "@/Layouts/PageLayout";

export default function Privacy({content, auth, sessionFlash}: PageProps<{ content: string }>) {

        return (
            <PageLayout header={auth.user ? 'Privacy Policy' : null }>
                <>
                    <Head title="Privacy Policy">
                        <meta name="description" content="Subtitle Extractor privacy policy"/>
                    </Head>
                    {
                        auth.user ?
                            <div className="container mx-auto max-w-8xl mt-6 px-12 py-8 bg-white shadow-md  sm:rounded-lg">
                                <div dangerouslySetInnerHTML={{__html: content}}></div>
                            </div>
                            :
                            <div className="container mx-auto mt-6 px-6 py-4">
                                <div dangerouslySetInnerHTML={{__html: content}}></div>
                            </div>
                    }
                </>
            </PageLayout>
    )
}
