export interface ProgressSmootherConfig {
    averageTimeBetweenValues: number;
    averageValueIncreaseDelta: number;
    maxValue: number;
    delayUntilFirstValue: number;
    firstValue: number;
    started_at?: number;
}

export default class ProgressSmoother {
    private lastSetValue: number | undefined;
    private lastSetValueTime: number;
    private maxTransitionDuration = 3000;
    private config: ProgressSmootherConfig;
    private transitionStartValue: number = 0;
    private transitionTargetValue: number = 0;
    private transitionStartTime: number = Date.now();
    private isInTransition: boolean = false;

    public constructor(config: ProgressSmootherConfig) {
        this.config = config;
        this.lastSetValueTime = config.started_at || Date.now();
    }

    public smoothedValue(): number {
        const now = Date.now();
        if (this.lastSetValue === undefined) {
            return this.calculateInitialPredictedValue(now);
        }
        if (this.isInTransition) {
            return this.calculateTransitionValue(now);
        }
        return this.calculateNextPredictedValue(now);  // Use this for ongoing estimations
    }

    public setValue(value: number): void {
        if (value < this.smoothedValue()) {
            return;
        }
        const now = Date.now();
        this.transitionStartValue = this.smoothedValue();  // Start from the current smoothed value
        this.transitionTargetValue = Math.min(value, this.config.maxValue);
        this.transitionStartTime = now;
        this.isInTransition = true;  // Set the transition flag
        this.lastSetValue = this.transitionTargetValue;
        this.lastSetValueTime = now;
    }

    /**
     * Calculates the initial predicted progress value. It progresses linearly up to a specified transition point,
     * then continues with an exponential slowdown. The exponential phase asymptotically approaches the firstValue
     * without reaching it, controlled by a decay rate.
     */
    private calculateInitialPredictedValue(now: number): number {
        const decayFactor = 2;
        const transitionPoint = 0.5;
        const millisElapsed = now - this.lastSetValueTime;
        const percentageOfProgress = this.config.delayUntilFirstValue > 0 ? millisElapsed / this.config.delayUntilFirstValue : 1;

        if (percentageOfProgress <= transitionPoint) {
            return percentageOfProgress * this.config.firstValue;
        } else {
            const x = (percentageOfProgress - 0.5) * decayFactor;
            const exponentialProgress = 1 - Math.exp(-x);
            return (transitionPoint * this.config.firstValue) + (exponentialProgress * transitionPoint * this.config.firstValue);
        }
    }

    private calculateNextPredictedValue(now: number): number {
        const millisElapsed = now - this.lastSetValueTime;
        const normalizedTime = Math.min(1, millisElapsed / this.config.averageTimeBetweenValues);
        const easeOutQuad = normalizedTime * (2 - normalizedTime);
        const predictedIncrease = this.config.averageValueIncreaseDelta * easeOutQuad;
        const predictedValue = this.lastSetValue! + predictedIncrease;
        const maxValue = this.lastSetValue! < this.config.maxValue ? (this.config.maxValue * 0.99) : this.config.maxValue
        return Math.min(predictedValue, maxValue);
    }

    private calculateTransitionValue(now: number): number {
        const transitionProgress = Math.min(1, (now - this.transitionStartTime) / this.getScaledTransitionDuration());
        if (transitionProgress >= 1) {
            this.isInTransition = false;  // Transition is complete
        }
        return this.transitionStartValue + (this.transitionTargetValue - this.transitionStartValue) * transitionProgress;
    }

    private getScaledTransitionDuration(): number {
        const delta = Math.abs(this.transitionTargetValue - this.transitionStartValue);
        const scaledDuration = (delta / this.config.averageValueIncreaseDelta) * this.maxTransitionDuration;
        return Math.min(scaledDuration, this.maxTransitionDuration);
    }

    public subscribeToUpdates(callback: (value: number) => void, frequency = 250): () => void {
        const interval = setInterval(() => {
            const value = this.smoothedValue();
            callback(value);
            if(value >= this.config.maxValue) {
                clearInterval(interval);
            }
        }, frequency)
        return () => clearInterval(interval);
    }

}



