
type HowItWorksStep = {
    title: string,
    description: string,
}

export type HowItWorksProps = {
    steps: [HowItWorksStep, HowItWorksStep, HowItWorksStep]
}

export default function ({ steps: [step1, step2, step3] }: HowItWorksProps) {
    return (
        <section className="bg-gray-50 py-14 my-10">
            <div className="container mx-auto">

                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">

                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-5">
                        <div className="text-center mb-10">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                How does it work?
                            </h2>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                            <div
                                className="flex flex-col items-center p-6 text-center bg-white rounded-lg border shadow-md">
                                <div className="flex items-center justify-center w-10 h-10 bg-red-100 rounded-lg mb-2">
                                    <span className="text-xl font-bold text-red-600">1</span>
                                </div>
                                <h3 className="mb-2 text-xl font-bold text-gray-900">{step1.title}</h3>
                                <p className="font-normal text-gray-700">{step1.description}</p>
                            </div>


                            <div
                                className="flex flex-col items-center p-6 text-center bg-white rounded-lg border shadow-md">
                                <div className="flex items-center justify-center w-10 h-10 bg-blue-100 rounded-lg mb-2">
                                    <span className="text-xl font-bold text-blue-600">2</span>
                                </div>
                                <h3 className="mb-2 text-xl font-bold text-gray-900">{step2.title}</h3>
                                <p className="font-normal text-gray-700">{step2.description}</p>
                            </div>


                            <div
                                className="flex flex-col items-center p-6 text-center bg-white rounded-lg border shadow-md">
                                <div
                                    className="flex items-center justify-center w-10 h-10 bg-green-100 rounded-lg mb-2">
                                    <span className="text-xl font-bold text-green-600">3</span>
                                </div>
                                <h3 className="mb-2 text-xl font-bold text-gray-900">{step3.title}</h3>
                                <p className="font-normal text-gray-700">{step3.description}</p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </section>
    )
}
