import {ExtractionMethod} from "@/types/enums";
import axios from "axios";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";

export type ExtractionLanguages = {
    [ExtractionMethod.OCR]: Record<string, string>
    [ExtractionMethod.AUDIO]: Record<string, string>
    [ExtractionMethod.MANUAL]: Record<string, string>
    "common": Record<string, string>
}

async function getSupportedLanguages(): Promise<ExtractionLanguages> {
    const response = await axios.get(route('languages'));
    return response.data;
}

export default function useExtractionLanguages() {
    const [isLoading, setIsLoading] = useState(true);
    const [languageOptions, setLanguageOptions] = useState<ExtractionLanguages>({
        [ExtractionMethod.OCR]: {},
        [ExtractionMethod.AUDIO]: {},
        [ExtractionMethod.MANUAL]: {},
        common: {}
    })

    useEffect(() => {
        getSupportedLanguages()
            .then(res => setLanguageOptions(res))
            .then(() => setIsLoading(false))
            .catch(() => toast.error('Failed to load languages'))
    }, []);

    return [languageOptions, isLoading] as const;
}


