import {useEffect, useState} from "react";
import axios from "axios";

export type PaginatedSource<T> = {
    items: T[]
    isLoading: boolean
    initialized: boolean
    updateItems: (cb: (curr: T[]) => T[]) => void
    reloadItems: (extraParams? : Record<any, any>) => Promise<T[]>
    error: string | null
    pagination: {
        currentPage: number
        lastPage: number
        total: number
    }
    nextPage: () => void
    prevPage: () => void
    setPage: (page: number, forceReload: boolean) => void
}

const usePaginatedSource = <T>(routeName: string, perPage: number = 10): PaginatedSource<T> =>  {
    const [targetPage, setTargetPage] = useState(1);
    const [state, setState] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        items: [] as T[],
        isLoading: true,
        error: null as string | null,
        initialized: false
    });

    function fetchItems(page: number, extraParams: Record<any, any> = {}) : Promise<T[]> {
        setState(prev => ({...prev, isLoading: true, error: null }))
        return axios.get(route(routeName, {page, perPage, ...extraParams}))
            .then(response => {
                if(response.data.current_page === targetPage) {
                    setState(prev => ({
                        ...prev,
                        currentPage: response.data.current_page,
                        lastPage: response.data.last_page,
                        total: response.data.total,
                        items: response.data.data,
                        initialized: true
                    }));
                }
                return response.data.data;
            })
            .catch(error => {
                setState(prev => ({...prev, error: `Error fetching items: ${error}`}));
                return [];
            }).finally(() => {
                setState(prev => ({...prev, isLoading: false}));
            })
    }

    const updateItems = (cb: (curr: T[]) => T[]) => {
        setState((curr) => ({
            ...curr,
            items: cb(curr.items)
        }))
    }

    const reloadItems = (extraParams : Record<any, any> = {}) => {
        return fetchItems(state.currentPage, extraParams);
    }

    useEffect(() => {
        fetchItems(targetPage)
    }, [targetPage]);

    const nextPage = () => {
        if (state.currentPage < state.lastPage && targetPage === state.currentPage) {
            setTargetPage(state.currentPage + 1)
        }
    };

    const prevPage = () => {
        if (state.currentPage > 1 && targetPage === state.currentPage) {
            setTargetPage(state.currentPage - 1)
        }
    };

    const setPage = (page: number, forceReload: boolean = false) => {
        if(page === state.currentPage) {
            if(forceReload) {
                fetchItems(page);
            }
        } else if (page >= 1 && page <= state.lastPage) {
            setTargetPage(page);
        }
    }

    return {
        items : state.items,
        isLoading : state.isLoading,
        initialized: state.initialized,
        error: state.error,
        pagination: { currentPage: state.currentPage, lastPage: state.lastPage, total: state.total },
        updateItems,
        reloadItems,
        nextPage,
        prevPage,
        setPage
    }
};

export default usePaginatedSource;
