import {Subtitle} from "@/types";
import {parseSrt, subtitlesToSrt} from "@/utils/subtitleUtils";
import axios from "axios";
import {triggerDownload} from "@/utils/fileUtils";

export async function addTranslation (jobId: string, translated: Subtitle[], language: string) {
    const content = subtitlesToSrt(translated);
    const url = route('subtitle.addLanguage', jobId);
    try {
        await axios.post(url, {content, language});
    } catch (err: any) {
        const msg = err.response?.data?.message || 'Failed to add translation';
        throw new Error(msg);
    }
}

export async function getSubtitles(jobId: string) {
    return fetch(route('subtitle.json', {job: jobId}))
        .then(response => response.json())
        .then(data => parseSrt(data.content));
}

export function bulkDownloadSubtitles(jobId: string[], language: string | null = null) {
    const href = route('job.bulk-download', {ids: jobId, language})
    triggerDownload(href)
}
