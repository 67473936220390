import React, { useState, useCallback, useEffect, useRef } from 'react';

interface FrameMaskProps {
    imageHeight: number;
    state: { yStart: number, height: number}
    onChange: (state: { yStart: number, height: number }) => void;
}

const MIN_HEIGHT = 10;
const HEIGHT_FRACTION = 0.3;
const MAX_HEIGHT_FRACTION = 0.5;

const FrameMask: React.FC<FrameMaskProps> = ({  imageHeight, state, onChange }) => {
    const yPosition = state.yStart
    const height = state.height
    const isTouch = useRef(false);

    useEffect(() => {
        if (imageHeight > 0 && state.height === 0) {
            const newHeight = Math.min(imageHeight * HEIGHT_FRACTION, imageHeight * MAX_HEIGHT_FRACTION);
            const yStart = imageHeight - newHeight;
            onChange({ yStart, height: newHeight });
        }
    }, [imageHeight, onChange]);


    const handleStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        if (e.type === 'mousedown' && isTouch.current) return;
        isTouch.current = e.type === 'touchstart';

        e.preventDefault();
        const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        const startPosition = yPosition;

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'mousemove' && isTouch.current) return;
            const currentY = 'touches' in e ? (e as TouchEvent).touches[0].clientY : (e as MouseEvent).clientY;
            const deltaY = currentY - startY;
            const position = Math.max(0, Math.min(imageHeight - height, startPosition + deltaY));
            onChange({ yStart: position, height });
        };

        const handleEnd = () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            isTouch.current = false;
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
    }, [yPosition, height, imageHeight]);

    const handleResize = useCallback((position: 'top' | 'bottom') => (e: React.MouseEvent | React.TouchEvent) => {
        if (e.type === 'mousedown' && isTouch.current) return;
        isTouch.current = e.type === 'touchstart';

        e.preventDefault();
        e.stopPropagation();
        const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        const startHeight = height;
        const startPosition = yPosition;

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'mousemove' && isTouch.current) return;
            const currentY = 'touches' in e ? (e as TouchEvent).touches[0].clientY : (e as MouseEvent).clientY;
            const deltaY = currentY - startY;
            if (position === 'top') {
                const maxHeight = Math.min(startPosition + startHeight, imageHeight * MAX_HEIGHT_FRACTION);
                const newHeight = Math.max(MIN_HEIGHT, Math.min(startHeight - deltaY, maxHeight));
                const newPosition = startPosition + startHeight - newHeight;
                onChange({ yStart: newPosition, height: newHeight });
            } else {
                const newHeight = Math.max(MIN_HEIGHT, Math.min(startHeight + deltaY, imageHeight - startPosition, imageHeight * MAX_HEIGHT_FRACTION));
                onChange({ yStart: startPosition, height: newHeight });
            }
        };

        const handleEnd = () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            isTouch.current = false;
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
    }, [height, yPosition, imageHeight, onChange]);

    return (
        <div
            style={{
                position: 'absolute',
                top: yPosition,
                left: 0,
                width: '100%',
                height: height,
                backgroundColor: 'rgba(0, 255, 0, 0.3)',
                border: '1px solid rgba(0, 255, 0, 0.5)',
                cursor: 'move',
                touchAction: 'none',
            }}
            onMouseDown={handleStart}
            onTouchStart={handleStart}
        >
            <div
                style={{
                    position: 'absolute',
                    top: -5,
                    left: 0,
                    right: 0,
                    height: '10px',
                    cursor: 'ns-resize',
                }}
                onMouseDown={handleResize('top')}
                onTouchStart={handleResize('top')}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: -5,
                    left: 0,
                    right: 0,
                    height: '10px',
                    cursor: 'ns-resize',
                }}
                onMouseDown={handleResize('bottom')}
                onTouchStart={handleResize('bottom')}
            />
        </div>
    );
};

export default FrameMask;
