import {User} from "@/types";
import {Link} from "@inertiajs/react";
import React from "react";

export default function ApiOverview({ user }: { user?: User }) {
    return (
        <div
            className=" container mx-3 lg:mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:space-x-10 mt-5 lg:mt-0  justify-center items-center ">
            <div className="lg:w-1/2 mt-5 lg:mt-0">
                <h1 className="text-3xl mb-10">
                    Subtitle Extraction API
                </h1>
                <p className=" lg:mr-0">
                    Our Subtitle Extraction API seamlessly integrates advanced subtitle extraction directly into your applications. It supports both hardcoded and audio-based subtitles with high accuracy across multiple languages. If you’re a developer or a business looking to enhance your video content workflow, explore our documentation and start integrating today.
                </p>
                {
                    user && (
                        <Link
                            href={route('api-keys.index')}
                            className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 me-2  duration-150 mt-10 w-fit inline-block`}
                        >
                         Create API Key
                        </Link>
                    )
                }
                {
                    !user && (
                        <Link
                            href={route('register')}
                            className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 me-2  duration-150 mt-10 w-fit inline-block`}
                        >
                            Get Started
                        </Link>
                    )
                }


            </div>
            <div className="lg:w-1/2  lg:px-20 mr-10  mt-10 lg:mt-0">
                <img className="shadow-lg shadow-gray-400 rounded-lg" alt="Extracting hardcoded subtitles from video"
                     src="/img/api_code.png"/>
            </div>
        </div>
    )
}
