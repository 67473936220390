import VideoProbe from "@/services/video/VideoProbe";
import { useEffect, useState, useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Spinner from "@/Components/Spinner";
import FrameMask from "./FrameMask";
import PrimaryButton from "@/Components/PrimaryButton";

export type SubtitleRegionSelectProps = {
    videoFile: File,
    onSelection: (startY: number, endY: number) => void,
    offsetRatio?: [number, number],
}

const SAMPLE_FPS = 0.05;
const BUFFER_SIZE = 5;
const FRAMES_PER_LOAD = 10;
const MAX_ASPECT_RATIO = 2.8;

export default function ({ videoFile, onSelection, offsetRatio }: SubtitleRegionSelectProps) {
    const [videoProbe, setVideoProbe] = useState<VideoProbe>(new VideoProbe());
    const [selectionEnabled, setSelectionEnabled] = useState<boolean|null>(null);
    const [videoDuration, setVideoDuration] = useState(0);
    const [frames, setFrames] = useState<string[]>([]);
    const [currentIdx, setCurrentIdx] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [region, setRegion] = useState({ yStart: 0, height: 0 });
    const imageRef = useRef<HTMLImageElement>(null);
    const isMounted = useRef(false);
    const currentTime = (currentIdx + 1) / SAMPLE_FPS; // Add 1 to account for 0-based index

    const loadFrames = useCallback(async () => {
        if (currentTime >= videoDuration) return;

        setIsLoading(true);
        const remainingDuration = videoDuration - currentTime;
        const framesToLoad = Math.min(FRAMES_PER_LOAD, Math.floor(remainingDuration * SAMPLE_FPS));

        const samples = await videoProbe.getFrames(currentTime, SAMPLE_FPS, framesToLoad);
        if(!samples.length && !frames.length) {
            return setSelectionEnabled(false);
        }
        setFrames(prev => [...prev, ...samples]);
        setIsLoading(false);
    }, [currentTime, videoDuration, videoProbe]);

    useEffect(() => {
        if(selectionEnabled && videoDuration > 0 && !frames.length) {
            loadFrames();
        }
    }, [selectionEnabled, videoDuration, loadFrames]);

    useEffect(() => {
        isMounted.current = true;
        init(videoFile);
        return unmount;
    }, []);

    useEffect(() => {
        const remaining = frames.length - currentIdx - 1;
        const bufferedDuration = remaining / SAMPLE_FPS;
        if(
            remaining < BUFFER_SIZE &&
            frames.length &&
            !isLoading &&
            currentTime + bufferedDuration < videoDuration
        ) {
            loadFrames();
        }
    }, [currentIdx, frames, isLoading, loadFrames]);

    useEffect(() => {
        if(imageSize.height > 0 && offsetRatio) {
            setRegion({
                yStart: imageSize.height * offsetRatio[0],
                height: imageSize.height * (offsetRatio[1] - offsetRatio[0])
            });
        }
    }, [imageSize.height, offsetRatio]);

    useEffect(() => {
        const updateImageSize = () => {
            if (imageRef.current) {
                setImageSize({
                    width: imageRef.current.clientWidth,
                    height: imageRef.current.clientHeight
                });
            }
        };

        if (imageRef.current) {
            imageRef.current.onload = updateImageSize;
            if (imageRef.current.complete) {
                updateImageSize();
            }
        }
        window.addEventListener('resize', updateImageSize);
        return () => {
            if (imageRef.current) {
                imageRef.current.onload = null;
            }
            window.removeEventListener('resize', updateImageSize);
        };
    }, [currentIdx, frames]);

    function unmount() {
        isMounted.current = false;
        videoProbe.unload();
    }

    async function init(videoFile: File) {
        try {
            await videoProbe.load(videoFile);
            const metadata = videoProbe.getMetaData();
            const aspectRatio = metadata.width / metadata.height;
            setSelectionEnabled(aspectRatio < MAX_ASPECT_RATIO);
            setVideoDuration(metadata.duration);
        } catch (error) {
            if(isMounted.current) {
                throw error;
            }
        }
    }

    const goToPreviousFrame = () => {
        setCurrentIdx(prev => Math.max(0, prev - 1));
    }

    const goToNextFrame = () => {
        const nextIdx = currentIdx + 1;
        if (nextIdx < frames.length) {
            setCurrentIdx(nextIdx);
        } else if (currentTime < videoDuration) {
            loadFrames();
        }
    }

    const save = () => {
        const startRatio = region.yStart / imageSize.height;
        const endRatio = (region.yStart + region.height) / imageSize.height;
        onSelection(startRatio, endRatio);
    }

    const isNextDisabled = currentTime >= videoDuration;

    return (
        <div className="px-8 pb-6 w-full flex flex-col justify-center items-center min-h-60">
            {
                selectionEnabled === false ? (
                    <div className="text-center">
                        <p className="text-sm text-gray-600">This video does not support custom subtitle region selection</p>
                    </div>
                ) : (
                    frames.length ? (
                        <div className="relative">
                            <img
                                ref={imageRef}
                                className="rounded select-none pointer-events-none"
                                src={frames[currentIdx]}
                                alt="frame"
                            />
                            <FrameMask
                                state={region}
                                onChange={setRegion}
                                imageHeight={imageSize.height}
                            />
                            <div className="inline-flex w-full justify-between mt-3" role="group">
                                <div className="flex">
                                    <button
                                        type="button"
                                        onClick={goToPreviousFrame}
                                        disabled={currentIdx === 0}
                                        className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft}/>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={goToNextFrame}
                                        disabled={isNextDisabled}
                                        className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg border-l-0 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {isLoading && currentIdx === frames.length - 1 ? (
                                            <Spinner size={3} className="fill-blue-400"/>
                                        ) : (
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        )}
                                    </button>
                                </div>

                                <button
                                    onClick={save}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-lg text-xs px-4 py-0 me-2  duration-150">Save</button>
                            </div>
                        </div>
                    ) : (
                        <Spinner size={8} className="ml-2 fill-blue-300"/>
                    )
                )
            }
        </div>
    )
}
