import AuthenticatedLayout from '@/Layouts/Auth/AuthenticatedLayout';
import {PageProps} from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import {Head, Link, router} from "@inertiajs/react";
import PrimaryButton from "@/Components/PrimaryButton";
import Layout from "@/Pages/Api/ApiPageLayout";
import ApiOverview from "@/Pages/Api/ApiOverview";
import Checkbox from "@/Components/Checkbox";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import TextInput from "@/Components/TextInput";
import ApiTokenDisplay from "@/Pages/Api/Partials/ApiTokenDisplay";
import ClientRender from "@/Components/ClientOnly";

export type ApiKeysPageProps = {
    keys: any[],
    created_key: string | null
}

export default function ({ keys, created_key }: PageProps<ApiKeysPageProps>) {

    const deleteKey = async (id: string) => {
        if(!confirm('Are you sure you want to delete this API key?')) {
            return;
        }
        await axios.delete(route('api-keys.destroy', [id]));
        router.reload({ onSuccess: () => toast.success('API key deleted successfully')});
    }

    return (
        <>
            <Head title="Subtitle Extraction API">
                <meta name="description" content="Extract subtitles from videos using a simple API."/>
            </Head>
            <Layout>
                {
                    created_key && <ApiTokenDisplay apiKey={created_key} />
                }
                <div className="flex justify-between items-center mx-6 mt-8">
                    <h2 className="text-xl ">API Keys</h2>
                    <Link href={route('api-keys.create')}>
                        <PrimaryButton>Create API Key</PrimaryButton>
                    </Link>

                </div>
                <div className="mt-6 mx-6  bg-white border border-gray-200 rounded-lg shadow-sm">
                    {
                        keys.length > 0 ?
                            (
                                <table className="min-w-full divide-y  table-auto text-base">
                                    <thead className="">
                                    <tr>
                                        <th scope="col"
                                            className=" py-4 pl-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col"
                                            className=" py-4 pl-4 text-left">
                                            Created
                                        </th>
                                        <th scope="col"
                                            className=" py-4 pl-4 text-left">
                                            Expires
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {keys.map((apiKey) => (
                                        <tr key={apiKey.id} className="border-b">
                                            <td className="py-4 pl-4">
                                                <div className="flex items-center">
                                                    <span className="ml-2">{apiKey.name}</span>
                                                </div>
                                            </td>
                                            <td className="py-4 pl-4">
                                                <ClientRender>{moment(apiKey.created_at).format('MMM D, YYYY')}</ClientRender></td>
                                            <td className="py-4 pl-4">
                                                <ClientRender>{apiKey.expires_at ? moment(apiKey.expires_at).format('MMM D, YYYY') : '-'}</ClientRender>
                                            </td>
                                            <td className="text-right">
                                    <span title="Delete" onClick={() => deleteKey(apiKey.id)}>
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            className={`me-4 cursor-pointer text-gray-400 hover:text-gray-600`}
                                        />
                                    </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ):

                            (
                                <div className="p-4 text-center h-48 flex justify-center flex-col">
                                    <p className="text-gray-500">You have not created any API keys yet.</p>
                                    <p>
                                        <Link href={route('api-keys.create')} className="text-blue-500 hover:underline">
                                            Create an API key
                                        </Link>
                                    </p>

                                </div>
                            )
                    }

                </div>

            </Layout>
        </>
    )
}
